import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgChart(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.32065 2.32065C3.74249 0.898821 5.88475 0.339844 8.69984 0.339844H15.2998C18.1149 0.339844 20.2572 0.898821 21.679 2.32065C23.1009 3.74249 23.6598 5.88475 23.6598 8.69984V15.2998C23.6598 18.1149 23.1009 20.2572 21.679 21.679C20.2572 23.1009 18.1149 23.6598 15.2998 23.6598H8.69984C5.88475 23.6598 3.74249 23.1009 2.32065 21.679C0.898821 20.2572 0.339844 18.1149 0.339844 15.2998V8.69984C0.339844 5.88475 0.898821 3.74249 2.32065 2.32065ZM3.25403 3.25403C2.20087 4.3072 1.65984 6.01494 1.65984 8.69984V15.2998C1.65984 17.9847 2.20087 19.6925 3.25403 20.7457C4.3072 21.7988 6.01494 22.3398 8.69984 22.3398H15.2998C17.9847 22.3398 19.6925 21.7988 20.7457 20.7457C21.7988 19.6925 22.3398 17.9847 22.3398 15.2998V8.69984C22.3398 6.01494 21.7988 4.3072 20.7457 3.25403C19.6925 2.20087 17.9847 1.65984 15.2998 1.65984H8.69984C6.01494 1.65984 4.3072 2.20087 3.25403 3.25403ZM11.9998 8.03984C12.3644 8.03984 12.6598 8.33534 12.6598 8.69984V16.0478C12.6598 16.4124 12.3644 16.7078 11.9998 16.7078C11.6353 16.7078 11.3398 16.4124 11.3398 16.0478V8.69984C11.3398 8.33534 11.6353 8.03984 11.9998 8.03984ZM6.49984 9.95384C6.86435 9.95384 7.15984 10.2493 7.15984 10.6138V14.1338C7.15984 14.4984 6.86435 14.7938 6.49984 14.7938C6.13534 14.7938 5.83984 14.4984 5.83984 14.1338V10.6138C5.83984 10.2493 6.13534 9.95384 6.49984 9.95384ZM17.4998 9.95384C17.8644 9.95384 18.1598 10.2493 18.1598 10.6138V14.1338C18.1598 14.4984 17.8644 14.7938 17.4998 14.7938C17.1353 14.7938 16.8398 14.4984 16.8398 14.1338V10.6138C16.8398 10.2493 17.1353 9.95384 17.4998 9.95384Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgChart;
