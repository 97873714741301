import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgFeatureList(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6363 6.08445L18.6344 6.08561L18.6258 6.09088L18.5898 6.11321C18.5579 6.13313 18.5108 6.16281 18.4522 6.20071C18.3348 6.27667 18.1724 6.38479 17.993 6.51285C17.6229 6.77711 17.2237 7.09625 16.9831 7.37734C16.8067 7.58348 16.6044 7.89898 16.4364 8.18539C16.355 8.32422 16.2861 8.44854 16.2377 8.53796C16.2135 8.58257 16.1946 8.61826 16.1819 8.64242L16.1676 8.66965L16.1643 8.67613L16.1636 8.67748C16.0675 8.86453 15.8805 8.98776 15.6707 9.00211C15.4609 9.01646 15.2589 8.91992 15.1383 8.74765L14.1827 7.3829C13.9927 7.11145 14.0586 6.73733 14.3301 6.54727C14.6015 6.3572 14.9757 6.42318 15.1657 6.69463L15.5791 7.28506C15.7263 7.05078 15.8982 6.79948 16.0714 6.59707C16.4121 6.19905 16.912 5.81018 17.2957 5.53622C17.4933 5.39515 17.6715 5.27661 17.8004 5.19318C17.865 5.15138 17.9176 5.11818 17.9545 5.09516L17.9977 5.06838L18.0097 5.06105L18.0132 5.05895L18.0142 5.05831C18.0142 5.05831 18.0148 5.05793 18.3256 5.57119L18.0142 5.05831C18.2977 4.88669 18.6672 4.97698 18.8389 5.26044C19.0104 5.54386 18.9197 5.91279 18.6363 6.08445Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3999 18.9872C1.3999 21.5277 3.45939 23.5872 5.9999 23.5872L14.9999 23.5872C17.5404 23.5872 19.5999 21.5277 19.5999 18.9872L19.5999 13.9872C19.5999 13.6558 19.3313 13.3872 18.9999 13.3872C18.6685 13.3872 18.3999 13.6558 18.3999 13.9872L18.3999 18.9872C18.3999 20.865 16.8777 22.3872 14.9999 22.3872H5.9999C4.12214 22.3872 2.5999 20.865 2.5999 18.9872L2.5999 6.98721C2.5999 5.10944 4.12213 3.58721 5.9999 3.58721L9.9999 3.58721C10.3313 3.58721 10.5999 3.31858 10.5999 2.98721C10.5999 2.65583 10.3313 2.38721 9.9999 2.38721L5.9999 2.38721C3.45939 2.38721 1.3999 4.4467 1.3999 6.98721L1.3999 18.9872Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8999 17.9872C4.8999 18.3186 5.16853 18.5872 5.4999 18.5872L15.4999 18.5872C15.8313 18.5872 16.0999 18.3186 16.0999 17.9872C16.0999 17.6558 15.8313 17.3872 15.4999 17.3872L5.4999 17.3872C5.16853 17.3872 4.8999 17.6558 4.8999 17.9872Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2349 13.3872L5.4999 13.3872C5.16853 13.3872 4.8999 13.6558 4.8999 13.9872C4.8999 14.3186 5.16853 14.5872 5.4999 14.5872L10.2349 14.5872C10.5662 14.5872 10.8349 14.3186 10.8349 13.9872C10.8349 13.6558 10.5662 13.3872 10.2349 13.3872Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4999 2.5999C14.0769 2.5999 12.1126 4.56417 12.1126 6.98721C12.1126 9.41025 14.0769 11.3745 16.4999 11.3745C18.9229 11.3745 20.8872 9.41025 20.8872 6.98721C20.8872 4.56417 18.9229 2.5999 16.4999 2.5999ZM10.9126 6.98721C10.9126 3.90142 13.4141 1.3999 16.4999 1.3999C19.5857 1.3999 22.0872 3.90142 22.0872 6.98721C22.0872 10.073 19.5857 12.5745 16.4999 12.5745C13.4141 12.5745 10.9126 10.073 10.9126 6.98721Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgFeatureList;
