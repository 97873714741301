import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgBugReport(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_20032_457548)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.3999 5.9999C1.3999 3.45939 3.45939 1.3999 5.9999 1.3999H14.9999C17.5404 1.3999 19.5999 3.45939 19.5999 5.9999V10.9999C19.5999 11.3313 19.3313 11.5999 18.9999 11.5999C18.6685 11.5999 18.3999 11.3313 18.3999 10.9999V5.9999C18.3999 4.12213 16.8777 2.5999 14.9999 2.5999H5.9999C4.12213 2.5999 2.5999 4.12213 2.5999 5.9999V17.9999C2.5999 19.8777 4.12213 21.3999 5.9999 21.3999H9.9999C10.3313 21.3999 10.5999 21.6685 10.5999 21.9999C10.5999 22.3313 10.3313 22.5999 9.9999 22.5999H5.9999C3.45939 22.5999 1.3999 20.5404 1.3999 17.9999V5.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8999 6.9999C4.8999 6.66853 5.16853 6.3999 5.4999 6.3999H15.4999C15.8313 6.3999 16.0999 6.66853 16.0999 6.9999C16.0999 7.33127 15.8313 7.5999 15.4999 7.5999H5.4999C5.16853 7.5999 4.8999 7.33127 4.8999 6.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8999 10.9999C4.8999 10.6685 5.16853 10.3999 5.4999 10.3999H11.4999C11.8313 10.3999 12.0999 10.6685 12.0999 10.9999C12.0999 11.3313 11.8313 11.5999 11.4999 11.5999H5.4999C5.16853 11.5999 4.8999 11.3313 4.8999 10.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4999 22.7999C18.8747 22.7999 20.7999 20.8747 20.7999 18.4999C20.7999 16.1251 18.8747 14.1999 16.4999 14.1999C14.1251 14.1999 12.1999 16.1251 12.1999 18.4999C12.1999 20.8747 14.1251 22.7999 16.4999 22.7999ZM16.4999 23.9999C19.5375 23.9999 21.9999 21.5375 21.9999 18.4999C21.9999 15.4623 19.5375 12.9999 16.4999 12.9999C13.4623 12.9999 10.9999 15.4623 10.9999 18.4999C10.9999 21.5375 13.4623 23.9999 16.4999 23.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4999 15.7999C16.8313 15.7999 17.0999 16.0685 17.0999 16.3999V18.4999C17.0999 18.8313 16.8313 19.0999 16.4999 19.0999C16.1685 19.0999 15.8999 18.8313 15.8999 18.4999V16.3999C15.8999 16.0685 16.1685 15.7999 16.4999 15.7999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4999 19.7999C16.8313 19.7999 17.0999 20.0685 17.0999 20.3999V20.4499C17.0999 20.7813 16.8313 21.0499 16.4999 21.0499C16.1685 21.0499 15.8999 20.7813 15.8999 20.4499V20.3999C15.8999 20.0685 16.1685 19.7999 16.4999 19.7999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_20032_457548">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgBugReport;
