import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgLegacy(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3809 3.27038C12.5018 2.87966 11.4983 2.87966 10.6192 3.27038L5.62133 5.49164C4.38429 6.04144 3.59276 7.26008 3.66901 8.5691C3.84589 11.6059 4.37566 13.7238 5.39356 15.4596C6.41247 17.1971 7.95519 18.6115 10.2722 20.1802C11.3143 20.8857 12.6881 20.8868 13.7277 20.1813C16.0495 18.6057 17.5804 17.1778 18.5897 15.4379C19.5988 13.6984 20.1205 11.5881 20.313 8.58695C20.3977 7.26813 19.6043 6.03634 18.3589 5.48283L13.3809 3.27038ZM10.1318 2.17381C11.3212 1.64519 12.6789 1.64519 13.8683 2.17381L18.8463 4.38626C20.5123 5.12671 21.63 6.80315 21.5106 8.66379C21.3119 11.7605 20.7663 14.0773 19.6277 16.04C18.4894 18.0023 16.7924 19.5518 14.4016 21.1742C12.9539 22.1566 11.0466 22.1536 9.59945 21.1739C7.21834 19.5618 5.50797 18.0269 4.35842 16.0666C3.20786 14.1046 2.65378 11.7763 2.47104 8.63888C2.3633 6.78931 3.47976 5.13027 5.13396 4.39506L10.1318 2.17381Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3951 8.54853C15.6445 8.76674 15.6698 9.14579 15.4516 9.39518L12.5817 12.6751C12.0178 13.3194 11.0466 13.4057 10.378 12.8709L8.6252 11.4686C8.36645 11.2616 8.32449 10.884 8.5315 10.6253C8.73851 10.3665 9.11608 10.3245 9.37484 10.5316L11.1277 11.9338C11.2948 12.0675 11.5376 12.046 11.6786 11.8849L14.5485 8.60497C14.7667 8.35559 15.1457 8.33032 15.3951 8.54853Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgLegacy;
