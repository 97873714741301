import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgScanner(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58325 2.02067C9.59093 2.35195 9.3286 2.62673 8.99732 2.63441C6.16945 2.69997 4.77428 2.9858 3.84461 3.92835C3.03337 4.75082 2.70799 5.94273 2.5992 8.16812C2.58302 8.4991 2.3016 8.75429 1.97062 8.73811C1.63964 8.72193 1.38445 8.44051 1.40063 8.10953C1.51093 5.85326 1.84092 4.25093 2.99026 3.08567C4.30071 1.75708 6.18417 1.4993 8.96951 1.43473C9.30079 1.42706 9.57557 1.68939 9.58325 2.02067ZM14.4166 2.02067C14.4243 1.68939 14.699 1.42706 15.0303 1.43473C17.8157 1.4993 19.6991 1.75708 21.0096 3.08567C22.1589 4.25092 22.4889 5.85326 22.5992 8.10954C22.6154 8.44052 22.3602 8.72194 22.0292 8.73811C21.6982 8.75428 21.4168 8.49909 21.4006 8.16811C21.2919 5.94273 20.9665 4.75082 20.1553 3.92835C19.2255 2.98579 17.8304 2.69997 15.0025 2.63441C14.6712 2.62673 14.4089 2.35195 14.4166 2.02067ZM1.97062 15.6786C2.3016 15.6624 2.58302 15.9176 2.5992 16.2486C2.70798 18.474 3.03337 19.6658 3.84461 20.4883C4.77428 21.4309 6.16945 21.7168 8.99731 21.7823C9.32859 21.7899 9.59093 22.0647 9.58325 22.396C9.57558 22.7273 9.3008 22.9896 8.96952 22.9819C6.18416 22.9174 4.30071 22.6596 2.99026 21.331C1.84093 20.1657 1.51093 18.5635 1.40063 16.3071C1.38445 15.9762 1.63965 15.6947 1.97062 15.6786ZM22.0292 15.6786C22.3602 15.6947 22.6154 15.9762 22.5992 16.3071C22.4889 18.5635 22.1589 20.1657 21.0096 21.331C19.6991 22.6596 17.8157 22.9174 15.0303 22.9819C14.699 22.9896 14.4242 22.7273 14.4166 22.396C14.4089 22.0647 14.6712 21.7899 15.0025 21.7823C17.8304 21.7168 19.2255 21.4309 20.1552 20.4883C20.9665 19.6658 21.2919 18.474 21.4006 16.2486C21.4168 15.9176 21.6982 15.6624 22.0292 15.6786Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39992 11.6997C6.39992 8.84957 8.67353 6.52145 11.4999 6.52145C14.3263 6.52145 16.5999 8.84957 16.5999 11.6997C16.5999 12.9113 16.189 14.0286 15.4994 14.9127L17.4278 16.8746C17.6601 17.111 17.6568 17.4908 17.4205 17.7231C17.1842 17.9554 16.8043 17.9521 16.572 17.7158L14.6567 15.7672C13.7896 16.4622 12.6941 16.8779 11.4999 16.8779C8.67353 16.8779 6.39992 14.5497 6.39992 11.6997ZM11.4999 7.72145C9.35568 7.72145 7.59992 9.49274 7.59992 11.6997C7.59992 13.9066 9.35568 15.6779 11.4999 15.6779C13.6441 15.6779 15.3999 13.9066 15.3999 11.6997C15.3999 9.49274 13.6441 7.72145 11.4999 7.72145Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgScanner;
