import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgProfile(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0636 2.6C10.1507 2.6 8.60005 4.1507 8.60005 6.06359C8.60005 7.97648 10.1507 9.52717 12.0636 9.52717C13.9765 9.52717 15.5272 7.97648 15.5272 6.06359C15.5272 4.1507 13.9765 2.6 12.0636 2.6ZM7.40005 6.06359C7.40005 3.48796 9.48801 1.4 12.0636 1.4C14.6393 1.4 16.7272 3.48796 16.7272 6.06359C16.7272 8.63922 14.6393 10.7272 12.0636 10.7272C9.48801 10.7272 7.40005 8.63922 7.40005 6.06359ZM6.85651 14.7727C9.97465 12.9424 13.9255 12.9424 17.0436 14.7727C17.1482 14.8341 17.2767 14.9056 17.4217 14.9862C18.0519 15.3368 18.9933 15.8605 19.6389 16.4834C20.042 16.8724 20.4204 17.3807 20.4891 18.0006C20.5621 18.6589 20.2716 19.2787 19.6813 19.8331C18.6512 20.8005 17.4252 21.5661 15.8448 21.5661H8.05532C6.47498 21.5661 5.24887 20.8005 4.21882 19.8331C3.62849 19.2787 3.33796 18.6589 3.41096 18.0006C3.4797 17.3807 3.85806 16.8724 4.26126 16.4834C4.90678 15.8605 5.84812 15.3369 6.47838 14.9863C6.62334 14.9056 6.75185 14.8341 6.85651 14.7727ZM16.4361 15.8076C13.6931 14.1975 10.2071 14.1975 7.46396 15.8076C7.31802 15.8932 7.15724 15.9831 6.9882 16.0777C6.35806 16.43 5.61319 16.8465 5.09449 17.347C4.77177 17.6583 4.62705 17.9218 4.60365 18.1328C4.58451 18.3054 4.63279 18.5757 5.04031 18.9584L4.62957 19.3957L5.04032 18.9584C5.96005 19.8222 6.90548 20.3661 8.05532 20.3661H15.8448C16.9946 20.3661 17.94 19.8222 18.8598 18.9584C19.2673 18.5757 19.3156 18.3054 19.2965 18.1328C19.2731 17.9218 19.1283 17.6583 18.8056 17.3469C18.2869 16.8464 17.542 16.43 16.9118 16.0776C16.7428 15.9831 16.5821 15.8932 16.4362 15.8076L16.4361 15.8076Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgProfile;
