import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgDocumentation(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40527 8.9999C2.40527 4.80254 5.80791 1.3999 10.0053 1.3999H16.0243C18.5648 1.3999 20.6243 3.45939 20.6243 5.9999V12.9438C20.6243 13.2751 20.3557 13.5438 20.0243 13.5438C19.693 13.5438 19.4243 13.2751 19.4243 12.9438V5.9999C19.4243 4.12213 17.9021 2.5999 16.0243 2.5999H10.0053C6.47065 2.5999 3.60527 5.46528 3.60527 8.9999V17.7241C3.60527 19.6018 5.1275 21.1241 7.00527 21.1241H9.1222C9.45357 21.1241 9.7222 21.3927 9.7222 21.7241C9.7222 22.0555 9.45357 22.3241 9.1222 22.3241H7.00527C4.46476 22.3241 2.40527 20.2646 2.40527 17.7241V8.9999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18786 4.9999C9.18786 3.01168 10.7996 1.3999 12.7879 1.3999H15.4055C15.7369 1.3999 16.0055 1.66853 16.0055 1.9999C16.0055 2.33127 15.7369 2.5999 15.4055 2.5999H12.7879C11.4624 2.5999 10.3879 3.67442 10.3879 4.9999V6.59719C10.3879 8.04283 9.20863 9.21087 7.76306 9.19707L6.0228 9.18047C4.68843 9.16774 3.5999 10.2459 3.5999 11.5804V12.5047C3.5999 12.8361 3.33127 13.1047 2.9999 13.1047C2.66853 13.1047 2.3999 12.8361 2.3999 12.5047V11.5804C2.3999 9.5787 4.03269 7.96142 6.03425 7.98052L7.77451 7.99713C8.55289 8.00455 9.18786 7.37561 9.18786 6.59719V4.9999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5306 15.6324C17.7465 15.381 18.1253 15.3523 18.3767 15.5682L21.0361 17.8523C21.7761 18.488 21.78 19.6322 21.0442 20.2728L18.3797 22.5927C18.1298 22.8103 17.7508 22.7841 17.5332 22.5342C17.3156 22.2843 17.3418 21.9053 17.5917 21.6877L20.2562 19.3678C20.4402 19.2076 20.4392 18.9216 20.2542 18.7626L17.5948 16.4785C17.3434 16.2626 17.3147 15.8838 17.5306 15.6324Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7373 15.6324C14.9532 15.8838 14.9245 16.2626 14.6731 16.4785L12.0137 18.7626C11.8287 18.9216 11.8277 19.2076 12.0117 19.3678L14.6761 21.6877C14.9261 21.9053 14.9523 22.2843 14.7347 22.5342C14.5171 22.7841 14.1381 22.8103 13.8882 22.5927L11.2237 20.2728C10.4879 19.6322 10.4918 18.488 11.2318 17.8523L13.8912 15.5682C14.1426 15.3523 14.5214 15.381 14.7373 15.6324Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgDocumentation;
